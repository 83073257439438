<template>
  <div class="detailIfoBox">
    <div class="detailInfoTitle flex-sb">
      <p>{{ info.name }}</p>
			<img class="tag-read" :data-clipboard-text="url" v-if="ifShares" @click="inSharesFn" src="@/assets/image/shares.png" alt="">
    </div>
    <div class="detailInfoAuthor">
      <div class="df_Yc">
        <div class="d_authorAvatar">
          <img :src="authorInfo.avatar" alt="" />
        </div>
        <p class="f14 c_99">{{ authorInfo.username }}</p>
      </div>
      <div class="df_Yc">
        <div class="df_Yc mr40">
          <i class="iconfont c_99 f18 mr8">&#xe628;</i>
          <span class="c_99 f14">{{ info.view }}</span>
        </div>
        <div class="df_Yc">
          <i class="iconfont c_99 f18 mr8">&#xe63a;</i>
          <span class="c_99 f14">{{ info.down }}</span>
        </div>
      </div>
    </div>
    <!--素材-->
    <!--su模型-->
    <div>
      <div class="mt20 specification">
        <div class="specificationItem">
          <p class="c_99">更新时间：</p>
          <p class="c_33">{{ info.add_time }}</p>
        </div>
        <div class="specificationItem">
          <p class="c_99">文件大小：</p>
          <p class="c_33">{{ info.file_size }}</p>
        </div>
        <div class="specificationItem">
          <p class="c_99">作品风格：</p>
          <p class="c_33">{{ info.style }}</p>
        </div>
        <div class="specificationItem">
          <p class="c_99">作品格式：</p>
          <p class="c_33">{{ info.format }}</p>
        </div>
        <div class="specificationItem">
          <p class="c_99">推 荐：</p>
          <p class="c_33">{{ info.yc }}</p>
        </div>
      </div>
      <div class="df_Yc mt20" v-if="!info.zsStr">
        <img
          class="mxIcon"
          src="../../../assets/image/icon/mx-icon.png"
          alt=""
        />
        <p class="c_00BAA f12">免费素材</p>
      </div>
      <div class="df_Yc mt20 ckPrice" v-else>
        <p>参考价格：{{ info.zsStr * 2 }}知币</p>
      </div>
      <div class="detailActivityBox">
        <div class="discountPrice c_p">
          <div>
            <p class="f14 c_f46 f_b">限时折扣价</p>
            <div class="priceBox">
              <p class="f32 c_f46 f_b mr10">
                {{ info.zsStr ? info.zsStr : 0 }}
              </p>
              <p class="f12 c_bf">知币</p>
            </div>
          </div>
        </div>
        <div class="vipPrice">
          <div>
            <p class="f14 c_00BAA f_b">VIP专享价</p>
            <div class="priceBox">
              <p class="f32 c_00BAA f_b mr10">
                {{ info.is_vip ? 0 : ((info.zsStr || 0) * 0.8).toFixed(1) }}
              </p>
              <p class="f12 c_bf">知币</p>
            </div>
          </div>
        </div>
        <!-- <div class="vipPrice1">
          <div>
            <p class="f14 c_33 f_b">开通VIP仅需</p>
            <div class="priceBox">
              <p class="f32 c_33 f_b mr10">0.6</p>
              <p class="f12 c_bf">元/天</p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="buttonBox" v-if="info.isBaiduYun === 1">
        <div
          class="activeButton"
          :class="{ activeButtonPay: info.isPay == 1 }"
          @click="goYun"
        >
          网盘链接
        </div>
        <div class="getButton getButtonPay" v-if="info.isPay === 1">
          {{ info.baiduCode }}
        </div>
        <div class="getButton" v-else @click="getYunCode">获取提取码</div>
      </div>
      <div class="buttonBox" v-else>
        <div
          :class="{ freeDow: info.isPay, freeDow1: !info.isPay }"
          @click="download"
        >
          下载素材
        </div>
      </div>
    </div>

    <div class="detailInfoBottom" style="margin-bottom: 0.12rem">
      <div class="df_Yc c_p" @click="collect()">
        <i class="iconfont mr10 f22 c_cc c_p" :class="{ iconColor: isCollect }"
          >&#xe721;</i
        >
        <span class="f14 c_33 c_p">收藏</span>
      </div>
      <router-link :to="{ path: '/vip/material' }">
        <div class="df_Yc">
          <img src="../../../assets/image/icon/vip-icon.png" alt="" />
          <span class="f14 c_33">VIP会员</span>
        </div>
      </router-link>
      <a @click="pop">
        <div class="df_Yc">
          <img src="@/assets/image/icon/fk-icon.png" alt="" />
          <span class="f14 c_33">报错</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { getToken } from "@/utils/auth";
import { 
	getCollectView, 
	getCloudClassIsCollected, 
	checkDownTimes,
	getIsShareUser,
	getShareLink, } from "../../../Api";
import {mapGetters} from "vuex";
export default {
  name: "DetailInfo",
  props: ["info", "detailId", "authorInfo", "fid"],
  data() {
    return {
      isCollect: false,
      ffid: "7",
      collectPop: {},
			ifShares: false,
			url: '',
			share_id: '',
    };
  },
  created() {

    //获取课程是否收藏
    getCloudClassIsCollected({ pid: this.detailId, token: getToken() }).then(
      (res) => {
        this.isCollect = res.data.message == "collected" ? true : false;
      }
    );
		if (getToken()) {
			getIsShareUser({ token: getToken() }).then((res) => {
				if(res.data&&res.data.result == 1) {
					this.ifShares = true;
					getShareLink({ id: this.detailId, fid: this.fid, token: getToken() }).then((res) => {
						if(res.data&&res.data.url) {
							this.url = res.data.url
						}
					})
				} else {
					this.ifShares = false;
				}
			})
		}
		if (this.$route.query.shareId) {
			this.shareId = this.$route.query.shareId;
		}
  },
  mounted() {
    this.ffid = this.fid;
  },
  computed: {
    ...mapGetters([
      "isLogin"
    ]),
  },
  methods: {
    pop(){
      if(!this.isLogin){
        this.$login()
        return false
      }
      this.$reportErrorPop().show(this.detailId)
    },
    //查看云盘链接
    goYun() {
      if(!this.isLogin){
        this.$login()
        return false
      }
      // window.open(this.info.YunUrl, "_blank");
			checkDownTimes({token: getToken(), id: this.detailId}).then((res) => {
				if (res.data&&res.data.error == 0) {
					const downloadUrl =
							this.$ROOT_DIR +
							"/web/CloudLibraryDetail/down?id=" +
							this.detailId +
							"&token=" +
							getToken();
					let link = document.createElement("a");
					link.style.display = "none";
					link.target = '_blank';
					link.href = downloadUrl;
					document.body.appendChild(link);
					link.click();
				} else if (res.data&&res.data.error == -1) {
					this.$tooltipPop().show(1, res.data.msg)
				} else if (res.data&&res.data.error == -2) {
					this.$tooltipPop().show(2, res.data.msg)
				} else {
					this.$tooltipPop().show(0, res.data.msg)
				}
			}).catch((err) => {
				console.log(err);
			});
    },
    //获取云盘提取码
    getYunCode() {
      this.$emit("getYunCodeFun");
    },
		// 分享
		inSharesFn() {
      //先判断是否登录
      if (!getToken()) {
        this.$login();
        return
      }
			let clipboard = new Clipboard(".tag-read");
			clipboard.on("success", () => {
				this.$message.success("分享链接复制成功");
				clipboard.destroy();
			});
			clipboard.on("error", () => {
				this.$message.error("分享链接复制失败，请切换浏览器重试");
				clipboard.destroy();
			});
		},
    // 下载
    download() {
      this.$aboutPop().show(this.goDownload);
    },
    goDownload() {
      this.$emit("downloadFun", this.shareId);
    },
    collect() {
      //判断是否收藏过
      if (this.isCollect) {
        return;
      }
      getCollectView({ token: getToken(), fid: this.ffid }).then((res) => {
        if (res.data.error == "-1") {
          this.$login();
        }
        this.collectList = res.data;
        this.collectPop = this.$collectPop({
          collectList: this.collectList,
          detailInfo: this.info,
          pid: this.detailId,
          token: getToken(),
          fid: this.ffid,
        });
        this.collectPop.show();
      });
    },
  },
  watch: {
    "collectPop.isCollect"(val) {
      this.isCollect = val;
    },
  },
};
</script>

<style scoped>
.ckPrice {
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #040404;
  opacity: 1;
}
.freeDow {
  cursor: pointer;
  width: 100%;
  border-radius: 0.04rem;
  line-height: 0.46rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.16rem;
  background: #00baa2;
}
.freeDow1 {
  cursor: pointer;
  width: 100%;
  border-radius: 0.04rem;
  line-height: 0.46rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.16rem;
  background: #f46600;
}

.mxIcon {
  width: 0.12rem;
  height: 0.12rem;
  margin-right: 0.03rem;
}

.getButton {
  width: 1.75rem;
  height: 0.45rem;
  border-radius: 0.04rem;
  border: 0.01rem solid #f46600;
  line-height: 0.45rem;
  text-align: center;
  font-size: 0.16rem;
  color: #f46600;
  cursor: pointer;
}

.activeButton {
  width: 1.75rem;
  height: 0.45rem;
  background: #f46600;
  border-radius: 0.04rem;
  line-height: 0.46rem;
  text-align: center;
  font-size: 0.16rem;
  color: #ffffff;
  cursor: pointer;
}

.activeButtonPay {
  background: #00baa2;
}

.getButtonPay {
  border: 0.01rem solid #00baa2;
  color: #00baa2;
}

.buttonBox {
  width: 100%;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailInfoBottom {
  margin-top: 0.2rem;
  display: flex;
  justify-content: space-around;
}

.detailInfoBottom img {
  width: 0.18rem;
  height: 0.18rem;
  margin-right: 0.1rem;
}

.priceBox {
  display: flex;
  /*justify-content: center;*/
  align-items: baseline;
}

.detailActivityBox {
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.discountPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  /*text-align: center;*/
  width: 1.7rem;
  height: 0.8rem;
  background: url("../../../assets/image/bg/discountPrice-bg.png") no-repeat;
  background-size: 100% 100%;
}

.vipPrice1 {
  /*text-align: center;*/
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  width: 1.7rem;
  height: 0.8rem;
}

.vipPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  /*text-align: center;*/
  padding: 0.1rem;
  width: 1.7rem;
  height: 0.8rem;
  background: url("../../../assets/image/bg/vipPrice-bg.png") no-repeat;
  background-size: 100% 100%;
}

.dtlPrice {
  margin-top: 0.3rem;
  color: #040404;
  font-size: 0.14rem;
  text-decoration: line-through;
}

.specification .specificationItem:first-child {
  margin-top: 0;
}

.specificationItem {
  display: flex;
  justify-content: space-between;
  margin-top: 0.15rem;
}

.specification {
  background: #f7f6fb;
  width: 100%;
  border-radius: 0.04rem;
  padding: 0.2rem 0.1rem;
  font-size: 0.14rem;
}

.detailInfoAuthor {
  /* margin-top: 0.2rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d_authorAvatar {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  margin-right: 0.08rem;
  background: coral;
}

.d_authorAvatar img {
  width: 100%;
}

.detailInfoTitle {
  height: 0.56rem;
  width: 3.6rem;
  color: #040404;
  font-size: 0.2rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
	align-items: flex-start;
}
.detailInfoTitle img{
	width: 0.26rem;
	height: 0.26rem;
	cursor: pointer;
}
.detailInfoTitle p {
  width: 3.6rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.detailIfoBox {
  padding: 0.2rem 0.2rem 0 0.2rem;
}

.iconColor {
  color: #ff9e58;
}
.ckPrice {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #040404;
  opacity: 1;
  text-decoration: line-through;
}
</style>
